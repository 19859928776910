import styled from "styled-components";

export const hasBorders = (borders, theme) => {
  let styles = "";
  if (borders) {
    if (borders.right) {
      styles += `border-right: .7px ${theme.transparentGray} solid;`;
    }
    if (borders.left) {
      styles += `border-left: .7px ${theme.transparentGray} solid;`;
    }
    if (borders.bottom) {
      styles += `border-bottom: .7px ${theme.transparentGray} solid;`;
    }
    if (borders.top) {
      styles += `border-top: .7px ${theme.transparentGray} solid;`;
    }
  }
  return styles;
};

export const hideBorders = (borders) => {
  let styles = "";
  if (borders) {
    if (borders.right) {
      styles += "border-right: 0;";
    }
  }
  return styles;
};

const flexDirection = (direction) => `flex-direction: ${direction} !important;`;

export const Lines = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75px;
  margin-left: 40px;

  ${({ theme }) => theme.smartphone} {
    margin-left: 0px;
  }
`;

export const StrokeLineContainer = styled.div`
  margin-left: 15px;
`;

export const StrokeCircle = styled.div`
  height: 15px;
  width: 15px;
  background: none;
  border-radius: 50%;
`;

export const QuestionIcon = styled.img`
  position: absolute;
  right: 30px;
  top: 20px;
  width: 25px;
  height: 25px;
  ${(props) => props.theme.smartphone} {
    right: 10px;
    top: 10px;
  }
`;

export const StrokeLine = styled.div`
  height: 25px;
  width: 1px;
  margin-left: 7px;
  background: none;
  ${(props) => props.theme.smartphone} {
    height: 16px;
  }
`;

export const StrokeSmallLine = styled.div`
  height: 15px;
  width: 1px;
  margin-left: 7px;
  background: none;
  ${(props) => props.theme.smartphone} {
    height: 10px;
  }
`;

export const ShortLine = styled.div`
  height: 25px;
  width: 1px;
  border: 1px dashed ${(props) => props.theme.gray};
`;

export const LongLine = styled.div`
  height: 80%;
  width: 1px;
  border: 1px dashed ${(props) => props.theme.gray};
`;

export const FormLine = styled.div`
  height: 50px;
  width: 1px;
  border: 1px dashed ${(props) => props.theme.gray};
`;

export const LineCircle = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 15px;
  background: ${(props) => props.theme.gray};
`;

export const SubQuestionContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  background: ${(props) => (props.isSummary ? props.theme.white : props.theme.gray)};
`;

export const SubQuestion = styled.div`
  width: 100%;
`;

export const SubQuestionText = styled.span`
  display: block;
  font-family: ${(props) => (props.isSummary ? "Nunito" :"Roboto")};
  color: ${(props) => props.theme.tuatara};
  font-size: 18px;
  margin: ${(props) => (props.isSummary ? "12px 30px 8px" : "20px 30px")};
  ${(props) => props.theme.smartphone} {
    font-size: 13px;
  }
`;

export const QuestionOptions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  width: 20%;
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: ${(props) => (props.isSummary ? "5px" : "20px")};

  ${({ theme }) => theme.smartphone} {
    width: 60%;
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.background ? `background-color: ${props.theme.white};` : ""}
  ${(props) => hasBorders(props.borders, props.theme)}
  ${(props) => props.theme.smartphone} {
    padding: 0;
    ${(props) => (props.rotate ? "flex-direction: column !important;" : "")}
  }
  ${(props) => flexDirection(props.direction)}

  ${({ theme }) => theme.smartphone} {
    flex-wrap: wrap;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : "100%")};
  ${(props) => hasBorders(props.borders, props.theme)}
  ${(props) =>
    props.fatBorder
      ? `
      border-width: 3px; 
      border-color: ${
        props.colorBorder ? props.colorBorder : props.theme.black
      };`
      : ""}
  ${({ theme }) => theme.smartphone} {
    border-bottom: .7px ${({ theme }) => theme.transparentGray} solid;
    ${(props) => hideBorders(props.mobileBorders?.hide)}
    ${(props) => hasBorders(props.mobileBorders?.show, props.theme)}
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
  z-index: 9;
  background: none;
  border: none;
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 25px;
  width: 30px;
  ${(props) => props.theme.smartphone} {
    margin-top: 20px;
  }
`;

export const BackButtonImage = styled.img`
  height: 26px;
  width: 26px;
  object-fit: contain;
`;

export const BackContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  max-width: 1140px;
  width: 100%;
`;

export const FormHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 15px;
  background-color: ${(props) => props.theme.white};
  ${(props) => hasBorders(props.borders, props.theme)}
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

export const FormFooterRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.white};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  ${(props) => hasBorders(props.borders, props.theme)}
  ${(props) => props.theme.smartphone} {
    padding: 0;
  }
  ${(props) => flexDirection(props.direction)}
`;

export const FormContainer = styled.div`
  display: block;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const FormSubmitFooterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.brightTurquoiseTransparent};
  height: 80px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2;
`;

export const FormSectionContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  border-radius: 10px;
`;

export const FormTitle = styled.h3`
  color: ${(props) => props.theme.tuatara};
  font-family: "Roboto";
  font-size: 20px;
  font-weight: bold;
  margin: 20px 30px;
`;

export const TextHeader = styled.div`
  align-items: center;
  color: #31302f;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  flex-direction: row;
  height: 100%;
  padding: 0 30px;
  width: 100%;

  ${(props) => props.theme.smartphone} {
    font-size: 13px;
    padding: 0;
  }
`;
