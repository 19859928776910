export default {
  apis: {
    a2censo: {
      endpoints: {
        getCampaignList: {
          method: "get",
          uri: "campaigns?filter=active"
        },
        getCampaignActive: {
          method: "get",
          uri: "/campaigns?state=active&page={params.page}&limit={params.limit}"
        },
        getCampaignPagination: {
          method: "get",
          uri:
            "/campaigns?filter={params.filter}&page={params.page}&limit={params.limit}"
        },
        getCampaignAwarded: {
          method: "get",
          uri:
            "/campaigns?state=awarded&page={params.page}&limit={params.limit}"
        },
        getCampaignInvestment: {
          method: "get",
          uri:
            "/campaigns?state=active&page={params.page}&limit={params.limit}&{params.filters}"
        },
        getCampaign: {
          method: "get",
          uri: "campaign/{params.id}"
        },
        validateToken: {
          method: "post",
          uri: "/users/{params.userId}/validate-email"
        },
        validateOtp: {
          method: "post",
          uri: "/users/{params.userId}/validate-otp"
        },
        validateCancellationOtp: {
          method: "post",
          uri: "/users/{params.userId}/validate-otp?is_cancellation=true"
        },
        register: {
          method: "post",
          uri: "/users"
        },
        requestNewCode: {
          method: "get",
          uri: "/users/{params.userId}/otp"
        },
        requestCancellationOtpCode: {
          method: "get",
          uri: "/users/{params.userId}/otp?is_cancellation=true"
        },
        financialInfo: {
          method: "post",
          uri: "/enrollment/financial-info"
        },
        challengeQuestionnaire: {
          method: "get",
          uri: "/users/challenge-questionnaire"
        },
        challengeQuestionnaireAnswers: {
          method: "post",
          uri: "/users/validate-challenge-questionnaire"
        },
        forgotPassword: {
          method: "post",
          uri: "/users/forgot-password"
        },
        forgotPasswordOtpRequest: {
          method: "post",
          uri: "/users/forgot-password/send"
        },
        forgotPasswordOtpValidation: {
          method: "post",
          uri: "/users/forgot-password/otp-validation"
        },
        resetPassword: {
          method: "post",
          uri: "/users/reset-password"
        },
        getStaticParameters: {
          method: "get",
          uri: "/parameters?tables={params.tables}"
        },
        usersInfo: {
          method: "get",
          uri: "/users/info"
        },
        investment: {
          method: "post",
          uri: "/investment"
        },
        updateUsers: {
          method: "put",
          uri: "/users/{params.userId}"
        },
        getUSInformationQuestions: {
          method: "get",
          uri: "/questions?questionsType=fatca"
        },
        getPEPInformationQuestions: {
          method: "get",
          uri: "/questions?questionsType=pep"
        },
        saveGeneralInfo: {
          method: "post",
          uri: "/users/{params.userId}/general-information"
        },
        saveGeneralInfoPyme: {
          method: "post",
          uri: "/users/{params.userId}/general-information/pyme"
        },
        saveLegalRepresentative: {
          method: "post",
          uri: "/legal-representative"
        },
        purposeList: {
          method: "get",
          uri: "purpose?person_type={params.personType}"
        },
        savepurpose: {
          method: "post",
          uri: "purpose"
        },
        login: {
          method: "post",
          uri: "/login"
        },
        getSummaryBirthInfo: {
          method: "get",
          uri: "/summary-birth-data"
        },
        getSummaryFinancialInfo: {
          method: "get",
          uri: "/summary-financial-data"
        },
        getSummaryFamilyInfo: {
          method: "get",
          uri: "/summary-family-data"
        },
        getSummaryFatcaInfo: {
          method: "get",
          uri: "/summary-fatca-data"
        },
        getSummaryPepInfo: {
          method: "get",
          uri: "/summary-pep-data"
        },
        getSummaryPurposeInfo: {
          method: "get",
          uri: "/summary-purpose-data"
        },
        updateSummary: {
          method: "put",
          uri: "/summary"
        },
        getBirthData: {
          method: "get",
          uri: "/summary-birth-data"
        },
        refreshTokens: {
          method: "post",
          uri: "/refresh-tokens"
        },
        getLegalRepresentative: {
          method: "get",
          uri: "/legal-representative"
        },
        getAssociates: {
          method: "get",
          uri: "/associates"
        },
        saveAssociate: {
          method: "post",
          uri: "/associate"
        },
        amountValidation: {
          method: "post",
          uri: "/investment/validate-amount"
        },
        saveQualifiedInvestor: {
          method: "post",
          uri: "/qualified-investor"
        },
        getInvestmentsList: {
          method: "get",
          uri: "/investments"
        },
        amountToReturn: {
          method: "get",
          uri: "/investment/{params.investmentId}/amount"
        },
        createInvestment: {
          method: "post",
          uri: "/investment"
        },
        getTransaction: {
          method: "get",
          uri: "/transaction/{params.transactionId}"
        },
        getTransactionDetails: {
          method: "get",
          uri: "/transaction/{params.id}"
        },
        updateEnrollmentState: {
          method: "put",
          uri: "/enrollment-state"
        },
        getFeaturedCampaignList: {
          method: "get",
          uri: "campaigns?filter=featured"
        },
        getQualifiedInvestor: {
          method: "get",
          uri: "/qualified-investor?state={params.state}&token={params.token}"
        },
        deleteAssociate: {
          method: "delete",
          uri: "/associate/{params.id}"
        },
        validateOrganizationalStructure: {
          method: "get",
          uri: "/users/validate-organizational-structure"
        },
        logout: {
          method: "post",
          uri: "/logout"
        },
        sendEmail: {
          method: "post",
          uri: "/help/send-email"
        },
        getCity: {
          method: "get",
          uri: "/city/{params.id}"
        },
        rechargeAmountValidation: {
          method: "get",
          uri: "/balance/validate-amount/{params.amount}"
        },
        recharge: {
          method: "post",
          uri: "/balance"
        },
        balance: {
          method: "get",
          uri: "/balance"
        },
        moneyWithdrawal: {
          method: "post",
          uri: "/balance/retirement"
        },
        cancelInvestment: {
          method: "post",
          uri: "/cancellation-investment"
        },
        movements: {
          method: "get",
          uri:
            "/transactions/filtered?page={params.page}&size={params.size}{params.filters}"
        },
        politicsTermsHC: {
          method: "get",
          uri: "/policy-term-hc/last"
        },
        politicsTermsHCbyPersonType: {
          method: "get",
          uri: "/policy-term-hc/last?personType={params.personType}"
        },
        savePoliticsTermsHC: {
          method: "post",
          uri: "/policy-term-hc"
        },
        accountRecoverySendOtp: {
          method: "post",
          uri: "/user/unlock/send-otp"
        },
        accountRecoveryValidateOtp: {
          method: "post",
          uri: "/user/unlock/validate-otp"
        },
        getNewsList: {
          method: "get",
          uri: "/news"
        },
        getPayments: {
          method: "get",
          uri: "/payment/history{params.urlVars}"
        },
        paymentNewAmountRequest: {
          method: "put",
          uri: "/payment/new-amount-request"
        },
        getCampaignCompany: {
          method: "get",
          uri: "/campaign-company"
        },
        getPatrimonialRightsReport: {
          method: "get",
          uri:
            "/patrimonial-rights-report/?issuer_payment_id={params.issuer_payment_id}&page={params.page}&size={params.size}"
        },
        approvePayment: {
          method: "post",
          uri: "/payment/approve-reject"
        },
        sendRejectRequestRejection: {
          method: "post",
          uri: "/reject-request-rejection"
        },
        issuerPayment: {
          method: "post",
          uri: "/issuer-payment"
        },
        getHolidays: {
          method: "get",
          uri: "/holidays"
        },
        getHomeData: {
          method: "get",
          uri: "/cms/home"
        },
        getInvestmentSummary: {
          method: "get",
          uri: "/campaign/stats"
        },
        getFiltersCampaigns: {
          method: "get",
          uri: "/campaigns/active/filters"
        },
        getSimulatorData: {
          method: "get",
          uri: "/cms/simulator-detail"
        },
        getFileSimulator: {
          method: "post",
          uri: "/simulator/investment/detail-file"
        },
        getFileSimulatorPYME: {
          method: "post",
          uri: "/simulator/pyme/detail-file"
        },
        getPymeSelfAppraisal: {
          method: "get",
          uri: "/pyme-self-appraisal"
        },
        validateSelfAppraisal: {
          method: "post",
          uri: "/pyme-self-appraisal"
        },
        requestCampaigns: {
          method: "get",
          uri: "/request-campaigns?name={params.name}"
        },
        getCampaignByUser: {
          method: "get",
          uri: "/campaigns?user={params.userId}"
        },
        saveConfirmationDataPyme: {
          method: "post",
          uri: "/"
        },
        uploadFile: {
          method: "post",
          uri: "/files"
        },
        getMyCompanyInfo: {
          method: "get",
          uri: "/pyme"
        },
        setCompanyInfo: {
          method: "post",
          uri: "/pyme"
        },
        saveRequestCampaign: {
          method: "post",
          uri: "/request-campaign"
        },
        getRequestCampaignInfo: {
          method: "get",
          uri: "/request-campaign/info?id={params.id}"
        },
        canIInvest: {
          method: "get",
          uri:
            "/can-i-invest?document_type={params.documentType}&document_number={params.documentNumber}&person_type={params.personType}&name={params.name}&last_name={params.lastName}"
        },
        documentRequestCampaign: {
          method: "post",
          uri: "/document-request-campaign"
        },
        putDocumentRequestCampaign: {
          method: "put",
          uri: "/document-request-campaign"
        },
        deleteDocumentRequestCampaign: {
          method: "delete",
          uri: "/delete-document-request-campaign/{params.document_id}"
        },
        getDocumentStage: {
          method: "get",
          uri:
            "/document-stage?stage_id={params.stage}&document_origin_id={params.origin}&request_campaign_id={params.campaign_id}"
        },
        getDocumentRequestCampaign: {
          method: "get",
          uri:
            "/document-request-campaign?states={params.state}&stage={params.stage}&request_campaign_id={params.campaign_id}"
        },
        stateStageRequestCampaign: {
          method: "get",
          uri: "/state-stage-request-campaign/{params.requestCampaignId}"
        },
        stateCampaignInterview: {
          method: "get",
          uri: "/interview/{params.requestCampaignId}"
        },
        changeRequestCampaignState: {
          method: "post",
          uri: "/request-campaign/{params.requestCampaignId}/state"
        },
        createCodebtor: {
          method: "post",
          uri: "/request-campaign/codebtor"
        },
        getListCodebtor: {
          method: "get",
          uri: "/request-campaign/{params.requestCampaignId}/codebtor"
        },
        deleteCodebtor: {
          method: "delete",
          uri:
            "/request-campaign/{params.requestCampaignId}/codebtor?codebtor_id={params.codebtorId}&associate_id={params.associateId}"
        },
        editCodebtor: {
          method: "put",
          uri: "/request-campaign/codebtor"
        },
        codebtorValidate: {
          method: "post",
          uri: "/codebtor/validate"
        },
        campaignFinancialTerms: {
          method: "get",
          uri:
            "/campaign-financial-terms?request_campaign_id={params.requestCampaignId}"
        },
        campaignFinancialTermsApprove: {
          method: "post",
          uri: "/campaign-financial-terms/pyme-approve"
        },
        requestCampaignOTPSend: {
          method: "post",
          uri: "/request-campaign/{params.requestCampaignId}/otp"
        },
        requestCampaignOTPValidate: {
          method: "post",
          uri: "/request-campaign/{params.requestCampaignId}/validate-otp"
        },
        requestCampaignLastTerms: {
          method: "get",
          uri: "/request-campaign/terms"
        },
        requestCampaignAcceptTerms: {
          method: "post",
          uri: "/campaign-request-accepted-terms"
        },
        requestCampaignSaveBankInfo: {
          method: "post",
          uri: "/request-campaign/bank"
        },
        requestCampaignGetPreApprovalInfo: {
          method: "get",
          uri: "/request-campaign/{params.requestCampaignId}/pre-approval"
        },
        updatePathFileDocument: {
          method: "put",
          uri: "/document-request-campaign/path-file"
        },
        requestCampaignInfo: {
          method: "get",
          uri: "/request-campaign/{params.requestCampaignId}"
        },
        requestCampaignPublish: {
          method: "post",
          uri: "/request-campaign/{params.requestCampaignId}/publish"
        },
        saveRequestCampaignFiles: {
          method: "post",
          uri: "/request-campaign/{params.requestCampaignId}/files"
        },
        updateRequestCampaignFiles: {
          method: "put",
          uri: "/request-campaign/{params.requestCampaignId}/files"
        },
        removeRequestCampaignFiles: {
          method: "delete",
          uri:
            "/request-campaign/{params.requestCampaignId}/files?file={params.file}"
        },
        updateUserData: {
          method: "post",
          uri: "/user-data-update"
        },
        getAvailableCerts: {
          method: "get",
          uri: "/users/certificates"
        },
        sendHelpMessage: {
          method: "post",
          uri: "/help/send"
        },
        getCertificates: {
          method: "get",
          uri:
            "/users/certificates/list?cert_type={params.type}&year={params.year}"
        },
        downloadCertificate: {
          method: "get",
          uri: "/certificates/withholding/{params.certificateId}"
        },
        downloadCertificateTaxFinancial: {
          method: "get",
          uri: "/certificates/tax-financial/{params.certificateYear}"
        },
        downloadCertificateByToken: {
          method: "get",
          uri: "/certificates/get-certificate-by-token/{params.token}"
        },
        downloadReportAnualPyme: {
          method: "get",
          uri: "/certificates/report-anual/{params.certificateId}"
        },
        sendEmailFinancialTax: {
          method: "get",
          uri: "/certificates/send-financial-tax-email/{params.year}"
        },
        requestSendMassiveCertificates: {
          method: "post",
          uri: "/certificates/mass-mailing"
        },
        getCompanyAllInfo: {
          method: "get",
          uri: "/company/info"
        },
        updateCitiesCompany: {
          method: "post",
          uri: "/company/update/cities"
        },
        getAttemptsValidation: {
          method: "get",
          uri: "/users/attempts-validation?type={params.type}"
        },
        getInvestmentCampaign: {
          method: "get",
          uri: "/investment-campaign/{params.id}"
        },
        availabilityValidation: {
          method: "post",
          uri: "/investment/validate-availability"
        },
        sendPublishConfirmationEmail: {
          method: "post",
          uri: "/request-campaign/sendPublishConfirmationEmail"
        }
      }
    }
  },
  minimumInvestment: 200000,
  multiple: 50000
};
