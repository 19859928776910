import styled from "styled-components";
import { FormFooterRow, FormRow } from "../../components/Form/Styles";
import { PageSubtitle } from "../../styles/Styles";
import Input from "../../components/Input/Input";

export const ButtonContainer = styled.div`
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.brightTurquoiseTransparent};

  ${(props) => props.theme.smartphone} {
    height: 60px;
  }
`;

export const Button = styled.button`
  width: 215px;
  height: 49px;
  background-color: ${(props) => props.theme.white};
  border-radius: 24px;
  border: none;
  color: ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito";
  font-size: 17px;
  font-weight: 800;
  outline: none;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  ${(props) => props.theme.smartphone} {
    width: 170px;
    height: 40px;
    border-radius: 18px;
    font-size: 13px;
  }
`;

export const Form = styled.form`
  margin-top: 16px;
  .form-footer-row-custom {
    .input {
      margin-bottom: 0px;
      border-bottom: 0.8px rgba(200, 200, 201, 0.39) solid;
    }
  }
`;

export const ContainerAmount = styled.div`
  & > div {
    padding: 0;
  }
  & > div > div {
    margin-bottom: 0px;
  }
`;

export const OwnerAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .input {
    border-bottom: 0.8px rgba(200, 200, 201, 0.39) solid;
    margin: 0px;
  }
  .names-input {
    border-right: 0.7px rgba(200, 200, 201, 0.39) solid;
  }
`;

export const IdContainer = styled.div`
  display: flex;
  width: 100%;
  .content-select-container {
    width: 20%;
    border-bottom: 0.8px rgba(200, 200, 201, 0.39) solid;
    border-right: 0.7px rgba(200, 200, 201, 0.39) solid;
    ${(props) => props.theme.smartphone} {
      width: 27%;
    }
  }
`;

export const NamesContainer = styled(IdContainer)`
  ${(props) => props.theme.smartphone} {
    display: flex;
    flex-direction: column;
  }
`;

export const LastNamesContainer = styled(IdContainer)`
  .names-input {
    display: block;
    input {
      display: inline-block;
      width: 75%;
    }
    .input__tooltip-logo-container {
      display: inline-block;
      width: 20%;
    }
  }
  .input:last-child {
    display: block;
  }
`;

export const FormFooterRowCustom = styled(FormFooterRow)`
  padding: 0px !important;
`;

export const FormRowCustom = styled(FormRow)`
  padding: 0px !important;
`;

export const PageSubtitleCustom = styled(PageSubtitle)`
  line-height: 30px;
  font-size: 23px;
  ${(props) => props.theme.smartphone} {
    line-height: 18px;
    font-size: 14px;
  }
`;

export const PageSubtitleFooterCustom = styled(PageSubtitle)`
  line-height: 25px;
  ${(props) => props.theme.smartphone} {
    line-height: 18px;
    font-size: 14px;
  }
`;

export const DvInput = styled(Input)`
  border-left: 1px ${(props) => props.theme.backGray} solid;
  color: ${(props) => props.theme.middleGray};
  font-weight: 400;
  position: relative;
  height: 100%;
  display: block;
  width: 20%;
  &.input {
    display: block;
    input {
      display: inline-block;
      width: 65%;
    }
  }
  .input__tooltip-logo-container {
    display: inline-block;
    width: 20%;
  }
  ${(props) => props.theme.smartphone} {
    width: 30%;
    &.input {
      input {
        width: 58%;
      }
    }
  }
`;

export const MiddleMessage = styled.div`
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
  line-height: 30px;
  font-size: 23px;
  ${(props) => props.theme.smartphone} {
    line-height: 18px;
    font-size: 14px;
  }
`;
