import styled from "styled-components";

export const Button = styled.button`
  margin-left: 15px;
  width: ${(props) => (props.longRadioButton ? "125px" : props.isSummary ? "80px" : "100px")};
  width: ${(props) => (props.extendedTextRadioButton ? "155px" : "100px")};
  height:  ${(props) => (props.isSummary ? "32px" : "41px")};
  border-radius: 24px;
  border: ${(props) => (props.isSummary ? "2px" : "1px")} solid ${(props) => props.theme.brightTurquoise};
  font-family: "Nunito";
  font-size: ${(props) => (props.isSummary ? "16px" : "17px" )};
  font-weight: 700;
  outline: none;
  cursor: pointer;
  ${(props) =>
    props.check
      ? `
      background-color: ${props.theme.brightTurquoise};
      color: ${props.theme.white};
      font-weight: ${props.longRadioButton ? "normal" : 800};
    `
      : `
      background-color: ${
        props.longRadioButton ? props.theme.gray : props.theme.white
      };
      color: ${
        props.longRadioButton ? props.theme.black : props.theme.brightTurquoise
      };
      font-weight: ${props.longRadioButton ? "normal" : 800};
    `}
  ${(props) => props.theme.smartphone} {
    width: ${(props) => (props.extendedTextRadioButton ? "125px" : "100px")};
    height: 29px;
    border-radius: 18px;
    font-size: 13px;
    margin-right: 15px;
  }
`;

export default { Button };
