import React, {  useState, useEffect } from "react";
import "./CampaignConditions.scss";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { formatNumber } from "../../lib/utils/formats";
import Campaign from "../../lib/models/campaign.model";
import { capitalizeWord } from "../../lib/utils";

export type IProps = {
  info: any;
  type: any;
};

enum ICampaingTypes {
  Debt = 1,
  Shares = 2,
  ConvertibleNote = 3
}

const CampaignConditions = ({ info, type }: IProps) => {
  const { t } = useTranslation();
  const [width, setWidth] = useState(window.innerWidth);
  const handleResize = () => setWidth(window.innerWidth);
    
  useEffect(() => {
    var nheight = 0;
    var nheightMobil = 0;
    if((type === ICampaingTypes.Shares) && info?.sector && (info.sector.length>33)){      
      nheight = 22;
      if(info.sector.length>34){
        nheightMobil = 22;
      } 
    }
    document.documentElement.style.setProperty(
      "--heiht-screen",
      nheight + "px"
    );  
    document.documentElement.style.setProperty(
      "--heiht-screen-mobil",
      nheightMobil + "px"
    ); 
  },[]);
  
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    const nwidth = window.innerWidth-105; 
    document.documentElement.style.setProperty(
      "--width-screen",
      (nwidth) + "px"
    );
  });
  const interestRateNumber = Number(info?.interestRate);
  const interestRate = Number.isInteger(interestRateNumber)
    ? interestRateNumber
    : interestRateNumber.toFixed(2);

  const gracePeriodValue = (gracePeriod: number) => {
    if (gracePeriod === 0 || gracePeriod === null) {
      return t("campaign.description.gracePeriodCapitalNoApply");
    }
    if (gracePeriod === 1) {
      return `${gracePeriod} ${t(
        "campaign.description.gracePeriodCapitalMonth"
      )}`;
    }
    return `${gracePeriod} ${t(
      "campaign.description.gracePeriodCapitalMonths"
    )}`;
  };

  const riskMessage = () => {
    switch (info.classification) {
      case "Visionario":
        return {
          title: "financialInfo.risk.visionary.title",
          statement: "financialInfo.risk.visionary.statement",
          message: "financialInfo.risk.visionary.message"
        };
      case "Innovador":
        return {
          title: "financialInfo.risk.innovative.title",
          statement: "financialInfo.risk.innovative.statement",
          message: "financialInfo.risk.innovative.message"
        };
      case "Sostenible":
        return {
          title: "financialInfo.risk.sustainable.title",
          statement: "financialInfo.risk.sustainable.statement",
          message: "financialInfo.risk.sustainable.message"
        };
      case "Clasico":
        return {
          title: "financialInfo.risk.classic.title",
          statement: "financialInfo.risk.classic.statement",
          message: "financialInfo.risk.classic.message"
        };
      default:
        return {
          title: "financialInfo.risk.classic.title",
          statement: "financialInfo.risk.classic.statement",
          message: "financialInfo.risk.classic.message"
        };
    }
  };

  document.addEventListener("click", (evt) => { 
    let x = (evt.clientX / innerWidth) * 100 - 8;  
    let y =evt.clientY;  
    var previewXposition = (localStorage.getItem("xPositionTooltip") != null 
    ?localStorage.getItem("xPositionTooltip") : "0");
    var previewYposition =  (localStorage.getItem("yPositionTooltip") != null 
    ?localStorage.getItem("yPositionTooltip") : "0");
    var nvalx = Math.abs(Number(previewXposition)-x);
    var nvaly = Math.abs(Number(previewYposition)-y);
    if(nvaly > 10 || nvalx>10){
      localStorage.setItem("yPositionTooltip", y.toString());
      localStorage.setItem("xPositionTooltip", x.toString());
      document.documentElement.style.setProperty(
          "--mouse-x",
          x.toString() + "vw"
        );
    }
  });

  return (
    <div className="contidions">
      {type === ICampaingTypes.Shares ? (
        <div className="campaign">
          <div className="campaign__label">
            {t("campaignConditions.conditions")}
          </div>
          <div className="campaign__row_one">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.sharesQuantity")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {info?.sharesQuantity >= 0
                    ? formatNumber(info.sharesQuantity)
                    : ""}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipSharesQuantity"
                  data-for="tooltipSharesQuantity"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipSharesQuantity"
                  place="top"
                  effect="solid"
                  placement="topCenter"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("campaignConditions.tooltipSharesQuantity")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.sharesType")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {info.sharesType}
                </div>
                {info.tooltipSharesType ? <>
                  <div
                    className="campaign__row_one__column_one__item__icon"
                    data-tip="tooltipSharesType"
                    data-for="tooltipSharesType"
                  ></div>
                  <ReactTooltip
                    className="campaign__row_one__column_one__item__tooltipColor"
                    id="tooltipSharesType"
                    place="top"
                    effect="solid"
                  >
                    <div className="campaign__row_one__column_one__item__label">
                      {info.tooltipSharesType}
                    </div>
                  </ReactTooltip>
                </> : null}
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-two__Shares">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.sharesForPackage")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {info?.sharesForPackage >= 0
                    ? formatNumber(info.sharesForPackage)
                    : ""}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipSharesForPackage"
                  data-for="tooltipSharesForPackage"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipSharesForPackage"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("campaignConditions.tooltipSharesForPackage")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.minInversionPackage")}
              </div>
              <div className="campaign__row_one__column_one__item">
                  <div className="campaign__row_one__column_one__item__text display-flex">
                    <div style={{marginRight:"5px"}}>COP{" "}</div>
                    <div style={{marginBottom:"12px"}}>  
                      {info?.minInversionPackage >= 0
                        ? formatNumber(info.minInversionPackage)
                        : ""}
                    </div> 
                  </div>
                  <div
                    className="campaign__row_one__column_one__item__icon"
                    data-tip="tooltipMinInversionPackage"
                    data-for="tooltipMinInversionPackage"
                  ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipMinInversionPackage"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("campaignConditions.tooltipMinInversionPackage")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-three__Shares row-aux-value2">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.sharesPrice")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  COP{" "}
                  {info?.sharesPrice >= 0 ? formatNumber(info.sharesPrice) : ""}
                </div>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.patrimonialPercentageEmited")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {info?.patrimonialPercentageEmited >= 0
                    ? formatNumber(info.patrimonialPercentageEmited)
                    : ""}
                  %
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipPatrimonialPercentageEmited"
                  data-for="tooltipPatrimonialPercentageEmited"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipPatrimonialPercentageEmited"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("campaignConditions.tooltipPatrimonialPercentageEmited")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-four__Shares">
            <div className="campaign__row_one__column_one column-one_flex">
              <div className="campaign__row_one__column_one__label row-aux-value">
                {t("campaignConditions.companyValue")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  COP{" "}
                  {info?.companyValue >= 0
                    ? formatNumber(info.companyValue)
                    : ""}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipCompanyValue"
                  data-for="tooltipCompanyValue"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipCompanyValue"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("campaignConditions.tooltipCompanyValue")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two_flex row-aux-value2">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.investmentRoundType")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {info.investmentRoundType}
                </div>
                {info.tooltipInvestmentRoundType ? <>
                  <div
                    className="campaign__row_one__column_one__item__icon"
                    data-tip="tooltipInvestmentRoundType"
                    data-for="tooltipInvestmentRoundType"
                  ></div>
                  <ReactTooltip
                    className="campaign__row_one__column_one__item__tooltipColor"
                    id="tooltipInvestmentRoundType"
                    place="top"
                    effect="solid"
                  >
                    <div className="campaign__row_one__column_one__item__label">
                      {info.tooltipInvestmentRoundType}
                    </div>
                  </ReactTooltip>
                </> : null}
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-five__Shares">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.classification")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {info.classification}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip={riskMessage().title}
                  data-for={riskMessage().title}
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id={riskMessage().title}
                  place="top"
                  effect="solid"
                >
                  <div className="li-items">
                  <div className="campaign__row_one__column_one__item__container">
                      <p className="campaign__row_one__column_one__item__text--title">
                        <b>{t(`${riskMessage().title}`)}</b>
                      </p>
                      <p className="campaign__row_one__column_one__item__text--subtitle">
                        {t(`${riskMessage().statement}`)}
                      </p>
                      <ol className="campaign__row_one__column_one__item__text--item">
                        {t(`${riskMessage().message}`)
                          .split("%br")
                          .map((item) => (
                            <li
                              className="campaign__row_one__column_one__item__text--item__li"
                              key={item}
                            >
                              <span className="margin_span_tooltip">{item}</span>
                            </li>
                          ))}
                      </ol>
                    </div>
                  </div>                  
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two_flex">
              <div className="campaign__row_one__column_one__label">
                {t("campaignConditions.sector")}
              </div>
              <div className="campaign__row_one__column_one__item_sector">
                <div className="campaign__row_one__column_one__item_sector__text_sector">
                  {info.sector}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : type === ICampaingTypes.ConvertibleNote ? (
        <div className="campaign campaign_note">
          <div className="campaign__label">
            {t("campaignConditions.conditions")}
          </div>
          
          <div className="campaign__row_one row-three__Convertible__Note">
            <div className="campaign__row_one__column_one column-one_flex">
              <div className="campaign__row_one__column_one__label label-aux-value">
              {t("campaignConditions.cap")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text text-aux-value">
                COP{" "}
                  {info?.companyValue >= 0
                    ? formatNumber(info.companyValue)
                    : ""}
                </div>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two_flex row-aux-value3">
              <div className="campaign__row_one__column_one__label label-aux-value">
              {t("campaignConditions.discountRate")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text text-aux-value">
                  {info?.discountRate > 0
                      ? `${formatNumber(info.discountRate)}%`
                      : "No aplica"}
                </div>
              </div>
            </div>
            <div
              className="campaign__row_one__column_one__item__icon__Convertible__Note"
              data-tip="tooltipCap"
              data-for="tooltipCap"
            ></div>
            <ReactTooltip
              className="campaign__row_one__column_one__item__tooltipColor tooltipColor_note"
              id="tooltipCap"
              place="top"
              effect="solid"
            >
              <div className="campaign__row_one__column_one__item__label label-aux-value">
                {t("campaignConditions.tooltipCap")
                  .split("%br")
                  .map((item, index) => (
                    <div
                      className={`campaign__row_one__column_one__item__text--item__cap__${index}`}
                    >
                      {item}
                    </div>
                  ))}
              </div>
            </ReactTooltip>
          </div>

          <div className="campaign__row_one row-four__Convertible__Note">
            <div className="campaign__row_one__column_one column-one_flex">
              <div className="campaign__row_one__column_one__label label-aux-value">
                {t("campaignConditions.classification")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text text-aux-value">
                  {info.classification}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip={riskMessage().title}
                  data-for={riskMessage().title}
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id={riskMessage().title}
                  place="top"
                  effect="solid"
                >
                  <div className="li-items">
                    <div className="campaign__row_one__column_one__item__container">
                        <p className="campaign__row_one__column_one__item__text--title">
                          <b>{t(`${riskMessage().title}`)}</b>
                        </p>
                        <p className="campaign__row_one__column_one__item__text--subtitle">
                          {t(`${riskMessage().statement}`)}
                        </p>
                        <ol className="campaign__row_one__column_one__item__text--item">
                          {t(`${riskMessage().message}`)
                            .split("%br")
                            .map((item) => (
                              <li
                                className="campaign__row_one__column_one__item__text--item__li"
                                key={item}
                              >
                                <span className="margin_span_tooltip">{item}</span>
                              </li>
                            ))}
                        </ol>
                      </div>
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two_flex row-aux-value3">
              <div className="campaign__row_one__column_one__label label-aux-value">
                {t("campaignConditions.sector")}
              </div>
              <div className="campaign__row_one__column_one__item_sector">
                <div className="campaign__row_one__column_one__item_sector__text_sector text-aux-value">
                  {info.sector}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : type === ICampaingTypes.Debt ? (
        <div className="campaign campaign_debt">
          <div className="campaign__label">
            {t("campaignConditions.conditions")}
          </div>
          <div className="campaign__row_one">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.rate")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {`${interestRate}% E.A`}
                </div>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.term")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {`${info?.termInMonths} ${t("financialInfo.months")}`}
                </div>
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-two__Debt">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.interest")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {Campaign.paymentFrequency(info?.interestPaymentDebt)}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipInterest"
                  data-for="tooltipInterest"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipInterest"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("financialInfo.tooltipInterest")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.gracePeriodPayment")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {gracePeriodValue(info?.interestGracePeriod)}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipGracePeriodPayment"
                  data-for="tooltipGracePeriodPayment"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipGracePeriodPayment"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("financialInfo.tooltipGracePeriodPayment")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-three__Debt">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.capital")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {info?.capitalPaymentDebt >= 0
                    ? t(
                        `campaign.description.capitalPaymentValues.${info?.capitalPaymentDebt}`
                      )
                    : ""}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipCapital"
                  data-for="tooltipCapital"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipCapital"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("financialInfo.tooltipCapital")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.gracePeriodPaymentCapital")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {gracePeriodValue(info?.capitalGracePeriod)}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipGracePeriodPaymentCapital"
                  data-for="tooltipGracePeriodPaymentCapital"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipGracePeriodPaymentCapital"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("financialInfo.tooltipGracePeriodPaymentCapital")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-four__Debt">
            <div className="campaign__row_one__column_one column-one">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.warranty")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {`${
                    info?.guaranteedInvestmentPercentage
                      ? `FNG ${info?.guaranteedInvestmentPercentage}%`
                      : "No aplica"
                  }`}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip="tooltipWarranty"
                  data-for="tooltipWarranty"
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id="tooltipWarranty"
                  place="top"
                  effect="solid"
                >
                  <div className="campaign__row_one__column_one__item__label">
                    {t("financialInfo.tooltipWarranty")}
                  </div>
                </ReactTooltip>
              </div>
            </div>
            <div className="campaign__row_one__column_one column-two">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.classification")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text">
                  {t(`${riskMessage().title}`)}
                </div>
                <div
                  className="campaign__row_one__column_one__item__icon"
                  data-tip={riskMessage().title}
                  data-for={riskMessage().title}
                ></div>
                <ReactTooltip
                  className="campaign__row_one__column_one__item__tooltipColor"
                  id={riskMessage().title}
                  place="top"
                  effect="solid"
                >
                  <div className="li-items">
                  <div className="campaign__row_one__column_one__item__container">
                      <p className="campaign__row_one__column_one__item__text--title">
                        <b>{t(`${riskMessage().title}`)}</b>
                      </p>
                      <p className="campaign__row_one__column_one__item__text--subtitle">
                        {t(`${riskMessage().statement}`)}
                      </p>
                      <ol className="campaign__row_one__column_one__item__text--item">
                        {t(`${riskMessage().message}`)
                          .split("%br")
                          .map((item) => (
                            <li 
                              className="campaign__row_one__column_one__item__text--item__li"
                              key={item}
                            >
                              <span className="margin_span_tooltip">{item}</span>
                            </li>
                          ))}
                      </ol>
                    </div>
                  </div>                  
                </ReactTooltip>
              </div>
            </div>
          </div>

          <div className="campaign__row_one row-five__Debt">
            <div className="campaign__row_one__column_one">
              <div className="campaign__row_one__column_one__label">
                {t("financialInfo.sector")}
              </div>
              <div className="campaign__row_one__column_one__item">
                <div className="campaign__row_one__column_one__item__text__bottomtext">
                  {capitalizeWord(info?.sector ?? "")}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CampaignConditions;
