import App from "../app";

const redirectIfSuccess = () => {
  const successSummary =
    window.location.pathname + window.location.search === "/enrollment/success";
  return successSummary ? "" : "/my-balance";
};

const type = {
  natural: "natural",
  legal: "legal",
  pyme: "pyme"
};

const typeIds = {
  natural: 1,
  legal: 2,
  pyme: 3
};

const reasonType = {
  financing: 1,
  invest: 2,
  other: 3
};

const UpdateUserState = {
  maxAttempsPerDay: "maxAttempsPerDay",
  nameNotCoincidence: "nameNotCoincidence",
  apiError: "apiError"
};

export const documentTypes = {
  NIT: 3,
  CE: 2,
  CC: 1,
  PA: 4,
  NITE: 5
};

const User = {
  typeIds,
  type,
  reasonType,
  UpdateUserState,
  naturalIdTypes: [
    { value: 1, text: "CC" },
    { value: 2, text: "CE" },
    { value: 4, text: "PA" }
  ],
  legalIdTypes: [
    { value: 3, text: "NIT", textAux: "NIT" },
    {
      value: 5,
      text: "NIT (E) Número de identificación tributaria o equivalente",
      textAux: "NIT (E)"
    }
  ],
  documentType: {
    1: "CC",
    2: "CE",
    3: "NIT",
    4: "PA",
    5: "NITE"
  },
  documentTypeInvestmentAndRecharge: {
    1: "CC",
    2: "CE",
    3: "NIT"
  },
  documentTypeName: {
    NIT: 3,
    CE: 2,
    CC: 1,
    PA: 4,
    NITE: 5
  },
  docTypeFormatted: {
    1: "C.C.",
    2: "C.E.",
    3: "NIT.",
    4: "PA.",
    5: "NIT E."
  },
  personType: {
    investor: 1
  },
  investorType: {
    unknown: 0,
    qualified: 1,
    noQualified: 2
  },
  status: {
    pending: 0,
    active: 1,
    blocked: 2,
    suspended: 3,
    pendingRiskValidation: 4
  },
  state: {
    finished: 0,
    checkEmail: 2,
    confirmData: 4,
    identityValidation: 5,
    generalInformation: 6,
    financialInfo: 7,
    purpose: 8,
    Summary: 9,
    legalRepresentative: 10,
    pendingShareholder: 11,
    organizationalStructure: 12,
    pendingDocumentValidation: 13,
    selfAppraisal: 14,
    pendingRiskValidation: 4
  },
  getPersonType: ({ user }) => user.userInfo.user.person_type,

  getUriFromUserState: ({ enrollment_state, state, person_type }) => {
    const bIsPyme = person_type === type.pyme;
    let redirect = "/";

    const deepLink = App.redux.store.getState().appParams.deepLink;

    if (deepLink) {
      redirect = deepLink;
    } else if (!bIsPyme) {
      redirect = "/my-balance";
    }
    if (bIsPyme && state === User.status.pendingRiskValidation) {
      return "/enrollment/pending-validation";
    }
    switch (enrollment_state) {
      case 0:
        return bIsPyme ? redirect : deepLink ? deepLink : redirectIfSuccess();
      case 1:
      case 2:
        return "/check-email";
      case 3:
      case 4:
        return bIsPyme ? "/enrollment/confirm-data-pyme" : "/confirm-data";
      case 5:
        return "/enrollment/identity-validation";
      case 6:
        return "/enrollment/general-information";
      case 7:
        return "/enrollment/financial-info";
      case 8:
        return "/enrollment/purpose";
      case 9:
        return "/enrollment/summary";
      case 10:
        return bIsPyme
          ? "/enrollment/organizational-structure"
          : "/enrollment/legal-representative";
      case 11:
        return bIsPyme
          ? "/enrollment/organizational-structure"
          : "/enrollment/pending-shareholder";
      case 12:
        return "/enrollment/organizational-structure";
      case 13:
        return "/enrollment/success";
      case 14:
        return "/enrollment/self-appraisal";
      default:
        return redirect;
    }
  },
  DocumentsWithCountries: [
    documentTypes.CE,
    documentTypes.PA,
    documentTypes.NITE
  ],
  canBeCobetor: [documentTypes.CC, documentTypes.CE, documentTypes.NIT],
  activeSession: () => {
    const idToken = sessionStorage.getItem("idToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    return !!(idToken && refreshToken);
  }
};

export default User;
