import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageTitle = styled.h3`
  font-family: "Nunito", sans-serif;
  font-weight: bold;
  font-size: 25px;
  color: ${({ theme }) => theme.tuatara};
  border-left: solid ${({ theme }) => theme.brightTurquoise} 10px;
  padding-left: 20px;
  margin-top: 10%;
  margin-bottom: 40px;
  ${({ theme }) => theme.smartphone} {
    font-size: 20px;
  }
`;

export const Paragraph = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
  p {
    font-family: Roboto;
    color: ${({ theme }) => theme.tuatara};
    text-align: center;
    font-size: 18px;
    ${({ theme }) => theme.smartphone} {
      padding: 0 20px;
    }
  }
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 35%;
  ${({ theme }) => theme.smartphone} {
    width: 50%;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  width: 30%;
`;

export const CancelateButton = styled.span`
  color: ${({ theme }) => theme.brightTurquoise};
  font-size: 15px;
  margin-top: 15px;
  text-decoration: underline;
  cursor: pointer;
`;
