import React from "react";
// eslint-disable-next-line import/named
import { FormikProps, withFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "@bvcco/a2censo-component-lib";
import Spinner from "assets/icons/spinner.svg";

import {
  Label,
  Divider,
  Footer,
  CIIUDescription,
  CIIU,
  AlertIco,
  LinkError,
  InputWrapper,
  Loader
} from "./components";
import AutoComplete from "../Autocomplete/Autocomplete";
import { SchemaValidation } from "./UserDataForm.schema";
import { Input } from "./Input";
import AlertIcon from "assets/icons/triangle-alert.png";
import { Padding } from "components/Simulator/Styles";

export type CIIUList = {
  id: string;
  person_type: string;
};

export type FormUserDataValues = {
  firstName: string;
  secondName?: string;
  lastName: string;
  secondLastName?: string;
  ciiu: string;
};

export type FormProps = {
  ciiuList: CIIUList[];
  onSave: (values: FormUserDataValues) => void;
  onClose: () => void;
  knowLink: string;
  loading?: boolean;
  initialValues?: FormUserDataValues;
};

const DForm = ({
  values,
  ciiuList,
  initialValues,
  setFieldValue,
  handleBlur,
  knowLink = "#",
  touched,
  errors,
  handleSubmit,
  onClose,
  loading = false
}: FormProps & FormikProps<FormUserDataValues>) => {
  const { t } = useTranslation();

  const { firstName, secondLastName, secondName, ciiu, lastName } = values;
  const { ciiu: initialCiiu } = initialValues;

  const haveErrors = Object.keys(errors).length > 0;

  return (
    <form onSubmit={handleSubmit} data-testid="user-data-form">
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <Label>{t("updatePNData.form.firstName")}</Label>
          <Input
            id="firstName"
            onBlur={handleBlur}
            onChange={({ target: { value } }) =>
              setFieldValue("firstName", value.toUpperCase())
            }
            value={firstName?.toUpperCase()}
            data-testid="firstName"
            name="firstName"
            error={
              touched.firstName && errors.firstName ? errors.firstName : ""
            }
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Label>{t("updatePNData.form.otherName")}</Label>
          <Input
            id="secondName"
            onBlur={handleBlur}
            onChange={({ target: { value } }) =>
              setFieldValue("secondName", value.toUpperCase())
            }
            value={secondName?.toUpperCase()}
            data-testid="secondName"
            name="secondName"
            error={
              touched.secondName && errors.secondName ? errors.secondName : ""
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <Label>{t("updatePNData.form.lastName")}</Label>
          <Input
            id="lastName"
            onBlur={handleBlur}
            onChange={({ target: { value } }) =>
              setFieldValue("lastName", value.toUpperCase())
            }
            data-testid="lastName"
            value={lastName?.toUpperCase()}
            name="lastName"
            error={touched.lastName && errors.lastName ? errors.lastName : ""}
          />
        </div>
        <div className="col-md-6 col-sm-12">
          <Label>{t("updatePNData.form.secondLastName")}</Label>
          <Input
            id="secondLastName"
            value={secondLastName?.toUpperCase()}
            onBlur={handleBlur}
            onChange={({ target: { value } }) =>
              setFieldValue("secondLastName", value.toUpperCase())
            }
            data-testid="secondLastName"
            name="secondLastName"
            error={
              touched.secondLastName && errors.secondLastName
                ? errors.secondLastName
                : ""
            }
          />
        </div>
      </div>
      <Divider />
      <CIIUDescription className="row">
        <div className="col-md-12 col-12">
          {t("updatePNData.putInfo")} <strong>{t("updatePNData.ciiu")}</strong>{" "}
          {t("updatePNData.putInfoAdd")}
        </div>
      </CIIUDescription>
      <CIIUDescription className="row">
        <div className="col-md-6 col-sm-12">
          <ul>
            <li>
              <strong>{t("updatePNData.ciius.left1")}</strong>{" "}
              {t("updatePNData.ciius.0010")}
            </li>
            <li>
              <strong>{t("updatePNData.ciius.left2")}</strong>{" "}
              {t("updatePNData.ciius.0081")}
            </li>
          </ul>
        </div>
        <div className="col-md-6 col-sm-12">
          <ul>
            <li>
              <strong>{t("updatePNData.ciius.right1")}</strong>{" "}
              {t("updatePNData.ciius.0082")}
            </li>
            <li>
              <strong>{t("updatePNData.ciius.right2")}</strong>{" "}
              {t("updatePNData.ciius.0090")}
            </li>
          </ul>
        </div>
      </CIIUDescription>
      <div className="row">
        <div className="col-md-12 col-12 custom-alert">
          <Alert type="info">
            {t("updatePNData.doubts")},{" "}
            <a href={knowLink} target="_blank" rel="noreferrer">
              {t("updatePNData.knowHere")}
            </a>
          </Alert>
        </div>
      </div>
      <CIIU className="row">
        <div className="col-md-12 col-12">
          <Label>{t("updatePNData.ciiu")} *</Label>
          <AutoComplete
            id="ciiu"
            data-testid="ciiu"
            suggestions={ciiuList}
            maxLength="20"
            disabled={!!initialCiiu}
            value={ciiu}
            regExpValidation={/^[^\s]/}
            placeholder={t("confirmDataPyme.ciiuPlaceholder")}
            onChangeText={(code: string) => setFieldValue("ciiu", code)}
            name="ciiu"
            onBlur={handleBlur}
            error=""
          />
          <InputWrapper style={{ marginTop: -14 }}>
            {touched.ciiu && errors.ciiu && (
              <div data-testid="ciiu-error">
                <AlertIco src={AlertIcon} />
                <LinkError>{t(errors.ciiu)}</LinkError>
              </div>
            )}
          </InputWrapper>
        </div>
      </CIIU>
      <Footer className="row" loading={loading}>
        <Button
          rounded={true}
          outlined={true}
          color="cornFlowerBlueBg"
          onClick={onClose}
          dataTestid="on-close"
        >
          {t("jump")}
        </Button>

        <Button
          rounded={true}
          color="cornFlowerBlueBg"
          disabled={loading || haveErrors || !ciiu}
          type="submit"
          dataTestid="on-submit"
        >
          {loading ? t("updating") : t("update")}
          {loading && <Loader data-testid="loading" src={Spinner} />}
        </Button>
      </Footer>
    </form>
  );
};

export const UserDataForm = withFormik<FormProps, FormUserDataValues>({
  mapPropsToValues: ({ initialValues }) => ({
    firstName: initialValues?.firstName || "",
    lastName: initialValues?.lastName || "",
    secondName: initialValues?.secondName || "",
    secondLastName: initialValues?.secondLastName || "",
    ciiu: initialValues?.ciiu || ""
  }),
  handleSubmit: (values, { props: { onSave } }) => onSave(values),
  validationSchema: (props: FormProps) => SchemaValidation(props)
})(DForm);
