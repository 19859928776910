import { createReducer, createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  otpRequest: ["payload"],
  otpRequestWithRedirect: ["userId"],
  identityValidationOtp: ["payload"],
  requestNewInvestmentOtp: ["userId", "token"],
  createCancellation: ["payload"],
  createWithdrawalTransaction: ["payload"],
  confirmMoneyWithdrawal: ["payload"],
  confirmReturnProcess: ["payload"],
  getTransactionInfo: ["payload"],
  investmentCancellationDataFail: ["payload"],
  identityValidationOtpError: ["error"],
  otpCodeError: ["error"],
  cleanIsRequesting: []
});

export const InvestmentCancellationTypes = Types;

export default Creators;

export const INITIAL_STATE = {
  error: "",
  isRequesting: false
};

const setCancellationError = (state, { payload }) => ({
  ...state,
  error: payload.error
});

const sendOtpRequest = (state) => ({ ...state, error: "" });

const identityValidationOtp = (state) => ({ ...state, error: false });

const identityValidationOtpError = (state) => ({ ...state, error: true });

const createWithdrawalTransaction = (state) => ({
  ...state,
  isRequesting: true
});

const cleanIsRequesting = (state) => ({
  ...state,
  isRequesting: false
});

const otpCodeError = (state, { error }) => ({ ...state, error });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.INVESTMENT_CANCELLATION_DATA_FAIL]: setCancellationError,
  [Types.OTP_REQUEST]: sendOtpRequest,
  [Types.IDENTITY_VALIDATION_OTP_ERROR]: identityValidationOtpError,
  [Types.IDENTITY_VALIDATION_OTP]: identityValidationOtp,
  [Types.CREATE_WITHDRAWAL_TRANSACTION]: createWithdrawalTransaction,
  [Types.CLEAN_IS_REQUESTING]: cleanIsRequesting,
  [Types.OTP_CODE_ERROR]: otpCodeError
});
