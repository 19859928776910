/* eslint no-nested-ternary: 0 */
import React, { useState, useRef } from "react";
import "./InputSummary.scss";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const shouldShowPassword = (
  showPassword,
  setShowPassword,
  showPasswordIcon
) => {
  if (showPassword) {
    return (
      <button
        id="show-password"
        type="button"
        className="inputSummary__show-password-button"
        onClick={() => setShowPassword(false)}
      >
        <img
          src="/assets/images/show.png"
          alt="show"
          className="inputSummary__show-password"
        />
      </button>
    );
  }

  return (
    <button
      id="hide-password"
      type="button"
      className="inputSummary__show-password-button"
      onClick={() => setShowPassword(true)}
    >
      <img
        src="/assets/images/hide.png"
        alt="hidden"
        className={`inputSummary__hide-password ${
          showPasswordIcon ? "show" : "hidden"
        }`}
      />
    </button>
  );
};

const InputSummary = (props) => {
  const {
    error,
    id,
    showTooltip,
    extraClassname,
    type,
    readOnly,
    showPasswordIcon,
    className,
    inputType
  } = props;
  const filteredProps = { ...props };
  delete filteredProps.className;
  const isPassword = type === "password";
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();
  const renderTooltip = showTooltip && error;
  const isDisabled = readOnly ? "readOnly" : "available-field";

  const wheelRef = useRef();
  const onWheel = () => wheelRef.current.blur();
  return (
    <div className={`inputSummary ${className} ${isDisabled}`}>
      {inputType === "textarea" ? (
        <textarea
          {...props}
          className={`inputSummary__field ${extraClassname} ${isDisabled}`}
        />
      ) : (
        <input
          {...props}
          ref={wheelRef}
          onWheel={onWheel}
          className={`inputSummary__field ${extraClassname} ${isDisabled} ${
            isPassword ? "inputSummary__field-icon" : ""
          } ${error ? "inputSummary__field-error" : ""}`}
          type={isPassword ? (showPassword ? "text" : "password") : type}
        />
      )}
      {isPassword &&
        shouldShowPassword(showPassword, setShowPassword, showPasswordIcon)}
      {renderTooltip && (
        <>
          <div
            className="inputSummary__tooltip-logo-container"
            data-tip={true}
            data-for={`errorInfo-${id}`}
          >
            <img
              className="inputSummary__tooltip_logo"
              src="/assets/images/error.png"
              alt="error"
            />
          </div>

          <ReactTooltip
            className="error-tooltip__back"
            id={`errorInfo-${id}`}
            place="bottom"
            type="error"
            effect="solid"
          >
            <div className="error-tooltip__container">
              <p className="error-tooltip__text--title">{t(error)}</p>
            </div>
          </ReactTooltip>
        </>
      )}
    </div>
  );
};

InputSummary.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  id: PropTypes.string.isRequired,
  extraClassname: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  showTooltip: PropTypes.bool,
  readOnly: PropTypes.bool,
  showPasswordIcon: PropTypes.bool,
  inputType: PropTypes.string
};

InputSummary.defaultProps = {
  className: "",
  error: null,
  extraClassname: "non-class",
  inputType: "input",
  label: null,
  readOnly: false,
  showPasswordIcon: true,
  showTooltip: true,
  type: "text"
};

export default InputSummary;
