import styled, { css } from "styled-components";
import ArrowDownIcon from "../../assets/images/arrow_down_icon.png";
import ArrowDownIconWhite from "../../assets/images/arrow_down_icon_white.png";
import ArrowDownChevron from "../../assets/images/imageChevron.png";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  justify-content: center;
  &::after {
    content: "";
    background: ${(props) =>
      props.disableArrowSelect ? "unset" : props.isSummary ? `url(${ArrowDownChevron})` :`url(${ArrowDownIcon})`};
    background-size:  ${(props) => (props.hasborders ? "1rem;" : props.isSummary ? "16px;" :  "1.5rem;" )};
    ${(props) => (props.isSummary ?  props.isPourpose ? "top: 15px; right: 5px;" : "top: 25px;" : "")};
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        opacity: .5;
      `}
    background-repeat: no-repeat;
    position: absolute;
    width: ${(props) => (props.hasborders ? "24px;" : "27px;" )}; 
    height: ${(props) => (props.hasborders ? "14px;" : "16px;" )};
    pointer-events: none;
    right: ${(props) => (props.isPourpose ? "10px;": "20px;")};
    ${(props) => props.theme.tablet} {
      background-size: 1rem;
      background-repeat: no-repeat;
      width: 16px;
      height: 10px;
      right: 10px;
    }
  }

  &.campaigns {
    &::after {
      content: "";
      background: url(${ArrowDownIconWhite});
      background-size: 1.5rem;
      background-repeat: no-repeat;
      position: absolute;
      width: 27px;
      height: 16px;
      pointer-events: none;
      right: 20px;
      ${(props) => props.theme.tablet} {
        background-size: 1rem;
        background-repeat: no-repeat;
        width: 16px;
        height: 10px;
        right: 10px;
      }
    }
  }
`;

export const SelectContainer = styled.select`
  flex: 1;  
   ${(props) => (props.isSummary ? props.hasborders? "height: 40px;" :"height: 56px;" : "height: 100%;" )}
  padding: ${(props) => (props.isSummary ? props.isPourpose ? "8px 7px" : "8px 30px": "20px 30px;")};
  border: ${(props) => (props.hasborders ? "1px solid #A2AAAD;" : "none" )};
  background: 0 0 no-repeat;
  border-radius: 8px;
   ${(props) => (props.hasborders ? "margin-bottom: 12px;" : "" )};
  -webkit-appearance: none;
  outline: none;
  ${(props) => (props.isSummary ? `font-family: Nunito; color: ${props.theme.mako};` :
  `font-family: ${({ theme }) => theme.font.family.secondary}; color: ${props.theme.black}`)};
  font-size: ${(props) => (props.isSummary ? "18px;": "19px")};
  width: 100%;
 
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.silverChalice};
      &::after {
        visibility: hidden;
      }
    `}
  &:disabled {
    ${(props) => (props.isSummary ? "color: #C1C6C8;" :
    `color: ${({ theme }) => theme.silverChalice}`)};
  }
  ${(props) => props.theme.smartphone} {
    padding: 8px 10px;
    font-size: 13px;
    height: 40px;
  }

  &.campaigns {
    color: ${(props) => props.theme.white};
    background-color: ${(props) => props.theme.brightTurquoise};
    ${(props) => props.theme.tablet} {
      padding: 20px 20px 20px 10px;
      font-size: 14px;
      font-weight: bold;
      font-family: ${({ theme }) => theme.font.family.primary};
      margin-right: 24px;
    }
  }
`;

export const ErrorInfo = styled.div`
  padding: 0;
  position: absolute;
  right: 55px;
  width: 20px;
  ${(props) => props.theme.desktop} {
    padding: 0 10px 0 10px;
  }
  ${(props) => props.theme.smartphone} {
    right: 35px;
  }
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 35px;
  ${(props) => props.theme.desktop} {
    width: 15px;
    height: 15px;
  }
  ${(props) => props.theme.smartphone} {
    position: ${(props) => props.position || "unset"};
    left: 39%;
    top: 33%;
  }
`;
