import React from "react";
import PropTypes from "prop-types";
import { withFormik } from "formik";
import { push } from "connected-react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Input from "../../components/Input/Input";
import validationSchema from "./AccountRecovery.schema";
import Button from "../../components/Button/Button";
import {
  Container,
  Paragraph,
  PageTitle,
  InputContainer,
  ButtonContainer,
  CancelateButton
} from "./Styles";
import "./Styles.scss";

const AccountRecovery = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values, errors, handleChange, history } = props;
  const errorKeys = Object.keys(errors);
  return (
    <Container>
      <PageTitle>{t("accountRecovery.title")}</PageTitle>
      <Paragraph>
        <p>
          {t("accountRecovery.emailSubtitleInit")}
          <strong>{t("accountRecovery.emailSubtitleBold")}</strong>
          {t("accountRecovery.emailSubtitleEnd")}
        </p>
      </Paragraph>
      <InputContainer>
        <Input
          type="text"
          name="email"
          data-testid="recovery-email"
          onChange={handleChange}
          value={values.email}
          error={errors.email}
        />
      </InputContainer>
      <ButtonContainer>
        <Button
          className="large-button"
          buttonStyle="transparent-blue"
          testId="continue"
          onClick={() =>
            dispatch(push("/account-recovery/otp", { email: values.email }))
          }
          value={t("accountRecovery.continueButton")}
          largeButton={true}
          disabled={values.email === "" || !!errorKeys.length || errors.email}
        />
        <CancelateButton
          id="cancel-button"
          onClick={() => {
            history.replace({ state: undefined });
            dispatch(push("/login"));
          }}
        >
          {t("accountRecovery.cancelLink")}
        </CancelateButton>
      </ButtonContainer>
    </Container>
  );
};

AccountRecovery.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func
  }).isRequired
};

export default withFormik({
  mapPropsToValues: () => ({
    email: ""
  }),
  validationSchema
})(AccountRecovery);
