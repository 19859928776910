import { flatMap, catchError } from "rxjs/operators";
import { push } from "connected-react-router";
import { combineEpics, ofType } from "redux-observable";
import { Observable } from "rxjs-compat";
import App from "../../lib/app";
import LoginActions from "../reducers/login.reducer";
import ModalActions from "../reducers/modal.reducer";
import AccountRecoveryAction from "../reducers/account-recovery.reducer";

export const recoveryAccountOtpRequest = (action$) =>
  action$.pipe(
    ofType("START_ACCOUNT_RECOVERY_OTP_REQUEST"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.accountRecoverySendOtp({
          body: {
            meta: {},
            data: {
              attributes: {
                ...action.payload
              }
            }
          }
        })
      ).pipe(
        flatMap((response) =>
          Observable.concat(
            Observable.of(AccountRecoveryAction.recoveryAccountOtpSuccess({})),
            Observable.of(
              push("/account-recovery/otp-validation", {
                userMail: action.payload.email,
                method: action.payload.method,
                countryCode: response.country_code,
                phoneNumber: response.phone_number,
                dateGenerationToken: new Date,
                timerLimitMinutes: response.otp_timer_limit_minutes
              })
            )
          )
        ),
        catchError((response) => {
          if (response.errors[0].code === "006") {
            return Observable.concat(
              Observable.of(push("/account-recovery")),
              Observable.of(
                ModalActions.setConditionalModalState(
                  true,
                  "UserNotExistsModal"
                )
              )
            );
          }
          if (action.payload.method == "sms") {
            return Observable.concat(
              Observable.of(
                AccountRecoveryAction.recoveryAccountOtpCodeError(response)
              ),
              Observable.of(
                push("/account-recovery/otp-validation", {
                  method: action.payload.method,
                  countryCode: "",
                  phoneNumber: "",
                  dateGenerationToken: ""
                })
              )
            );
          }
          return Observable.of(push("/oops"));
        })
      )
    )
  );

export const identityValidationOtp = (action$) =>
  action$.pipe(
    ofType("START_ACCOUNT_RECOVERY_OTP_VALIDATION"),
    flatMap((action) =>
      Observable.from(
        App.api.a2censo.accountRecoveryValidateOtp({
          body: {
            data: {
              attributes: {
                email: action.payload.userMail,
                ...action.payload
              }
            }
          }
        })
      ).pipe(
        flatMap(() =>
          Observable.concat(
            Observable.of(AccountRecoveryAction.recoveryAccountOtpSuccess({})),
            Observable.of(LoginActions.cleanLoginError(0)),
            Observable.of(push("/account-recovery/success"))
          )
        ),
        catchError((response) => {
          if (action.payload.method == "sms") {
            return Observable.of(
              AccountRecoveryAction.recoveryAccountOtpCodeError(response)
            );
          }

          return Observable.of(
            AccountRecoveryAction.recoveryAccountValidationOtpError({
              error: response
            })
          )
        })
      )
    )
  );

export default combineEpics(recoveryAccountOtpRequest, identityValidationOtp);
