import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Input from "../../components/Input/Input";
import Select from "../../components/Select/Select";
import { PageTitle } from "../../styles/Styles";
import InvestmentCancellationActions from "../../redux/reducers/investment-cancellation.reducer";
import { moneyToNumber, numberToMoney } from "../../lib/formatter";
import User from "../../lib/models/user.model";
import {
  StrokeCircle,
  StrokeLineContainer,
  StrokeLine,
  TextHeader,
  FormHeaderRow,
  FormFooterRow
} from "../../components/Form/Styles";
import {
  ButtonContainer,
  Button,
  Form,
  OwnerAccountContainer,
  IdContainer,
  LastNamesContainer,
  NamesContainer,
  FormFooterRowCustom,
  FormRowCustom,
  PageSubtitleCustom,
  PageSubtitleFooterCustom,
  DvInput,
  MiddleMessage,
  ContainerAmount
} from "./Styles";
import moneyWithdrawalSchema from "./moneyWithdrawal.schema";

const MoneyWithdrawal = ({ location }) => {
  const { isValidated } = location.state;
  const dispatch = useDispatch();
  const availableMoney = useSelector(({ balance }) => balance.availableMoney);
  const userData = useSelector(({ user }) => user.userInfo.user);
  const { t } = useTranslation();
  const [selectPersonType, setPersonType] = useState(userData.person_type);
  const investmentCancellation = useSelector(
    (store) => store.investmentCancellation
  );

  const documentTypes = [
    { value: 1, text: "CC" },
    { value: 2, text: "CE" },
    { value: 3, text: "NIT" }
  ];

  const handleDocumentTypeChange = (value) => {
    if (Number(value) === 3) {
      setPersonType(User.type.legal);
    } else {
      setPersonType(User.type.natural);
    }
    return value;
  };

  const getAmount = (val) => {
    let amount = val.replace(/[^0-9.$]+/g, "");
    if (amount === "$0") {
      return "$ 0";
    }
    amount = moneyToNumber(amount, "withdrawal");
    return numberToMoney(amount);
  };

  // eslint-disable-next-line no-underscore-dangle
  const banks = location.state.response[1]._bank.map((bank) => ({
    value: bank.id,
    text: `${bank.name}`
  }));
  // eslint-disable-next-line no-underscore-dangle
  const bankAccountTypes = location.state.response[1]._bank_account_type.map(
    (type) => ({ value: type.id, text: `${type.name}` })
  );

  const initialValues = {
    documentType:
      userData.person_type === User.type.natural
        ? documentTypes[0].value
        : documentTypes[2].value,
    amount: "$ 0",
    personType: "1",
    bankName: "",
    bank: "",
    firstLastName: userData.last_name,
    confirmDocumentNumber: "",
    confirmBankNumber: "",
    names: userData.name,
    documentNumber: userData.document_number,
    company: "",
    bankAccountType: "",
    bankNumber: "",
    companyName: userData.name,
    dv2: userData.dv,
    secondLastName: userData.second_last_name || ""
  };

  const getFullNameUser = () => {
    if (selectPersonType === User.type.legal) {
      return userData.name;
    }

    let fullName = userData.name;

    if (userData.last_name) {
      fullName = `${fullName} ${userData.last_name}`;
    }
    if (userData.second_last_name) {
      fullName = `${fullName} ${userData.second_last_name}`;
    }

    return fullName;
  };

  const handleSubmitWithdrawal = (values) => {
    const bank = banks.find((item) => `${item.value}` === values.bank);
    const bankName = bank.text;
    const payload = {
      amount: Number(moneyToNumber(values.amount, "withdrawal")),
      bank: values.bank,
      bank_account_type: values.bankAccountType,
      account_number: values.bankNumber,
      bank_name: bankName,
      holder_names: values.names,
      holder_first_last_name: values.firstLastName,
      holder_second_last_name: values.secondLastName,
      holder_document_number: values.documentNumber,
      holder_document_type: values.documentType
    };

    if (selectPersonType === User.type.legal) {
      delete payload.holder_first_last_name;
      delete payload.holder_second_last_name;
      payload.holder_names = values.companyName;
      payload.holder_dv = values.dv2;
    }

    dispatch(
      InvestmentCancellationActions.createWithdrawalTransaction(payload)
    );
  };

  useEffect(
    () => () => {
      dispatch(InvestmentCancellationActions.cleanIsRequesting());
    },
    []
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={moneyWithdrawalSchema(selectPersonType, availableMoney)}
      id="withdrawal-form"
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleSubmitWithdrawal(values);
      }}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        handleChange,
        handleBlur,
        touched
      }) =>
        isValidated && (
          <Form
            className="container"
            onSubmit={handleSubmit}
            data-testid="withdrawal-data-container-form"
          >
            <PageTitle>{t("moneyWithdrawal.title")}</PageTitle>
            <PageSubtitleCustom>
              <strong>{getFullNameUser()}</strong>
              {t("moneyWithdrawal.description")}
            </PageSubtitleCustom>
            <StrokeLineContainer>
              <StrokeCircle />
              <StrokeLine />
            </StrokeLineContainer>
            <FormHeaderRow
              data-testid="moneyWithdrawal-amount-container"
              borders={{ bottom: true }}
            >
              <TextHeader>{t("moneyWithdrawal.amount")}</TextHeader>
            </FormHeaderRow>
            <ContainerAmount>
              <FormFooterRow background={true}>
                <Input
                  type="text"
                  name="amount"
                  label={t("moneyWithdrawal.amount")}
                  data-testid="amount-input"
                  placeholder={t("moneyWithdrawal.amount")}
                  value={`${values.amount}`}
                  onBlur={handleBlur}
                  className="names-input"
                  error={touched.amount && errors.amount}
                  onChange={(val) => {
                    setFieldValue("amount", getAmount(val.target.value));
                  }}
                />
              </FormFooterRow>
            </ContainerAmount>

            <StrokeLineContainer>
              <StrokeCircle />
              <StrokeLine />
            </StrokeLineContainer>
            <FormHeaderRow borders={{ bottom: true }}>
              <TextHeader>{t("moneyWithdrawal.accountHolder")}</TextHeader>
            </FormHeaderRow>
            <FormFooterRowCustom>
              <OwnerAccountContainer>
                <NamesContainer>
                  {selectPersonType === User.type.legal ? (
                    <Input
                      className="names-input"
                      label={t("moneyWithdrawal.companyName")}
                      hasBorder={true}
                      name="companyName"
                      disabled={true}
                      onBlur={handleBlur}
                      id="company_name"
                      placeholder={t("moneyWithdrawal.companyName")}
                      onChange={handleChange}
                      value={values.companyName}
                      error={touched.companyName && errors.companyName}
                    />
                  ) : (
                    <>
                      <Input
                        className="names-input"
                        label={t("moneyWithdrawal.names")}
                        hasBorder={true}
                        name="names"
                        id="names"
                        disabled={true}
                        placeholder={t("moneyWithdrawal.names")}
                        onChange={handleChange}
                        value={values.names}
                        onBlur={handleBlur}
                        error={touched.names && errors.names}
                      />
                      <LastNamesContainer>
                        <Input
                          className="names-input"
                          label={t("moneyWithdrawal.firstLastName")}
                          name="firstLastName"
                          id="first_last_name"
                          disabled={true}
                          onBlur={handleBlur}
                          placeholder={t("moneyWithdrawal.firstLastName")}
                          onChange={handleChange}
                          value={values.firstLastName}
                          error={touched.firstLastName && errors.firstLastName}
                        />
                        <Input
                          label={t("moneyWithdrawal.secondLastName")}
                          name="secondLastName"
                          disabled={true}
                          id="secondLastName"
                          placeholder={t("moneyWithdrawal.secondLastName")}
                          onChange={handleChange}
                          value={values.secondLastName}
                          error={
                            touched.secondLastName && errors.secondLastName
                          }
                        />
                      </LastNamesContainer>
                    </>
                  )}
                </NamesContainer>
                <IdContainer>
                  <Select
                    label={t("register.documentType")}
                    type="documentType"
                    disabled={true}
                    name="documentType"
                    testId="document-type-input"
                    id="documentType"
                    placeholder={t("register.documentType")}
                    defaultValue={
                      userData.person_type === User.type.natural
                        ? documentTypes[0].value
                        : documentTypes[2].value
                    }
                    options={documentTypes}
                    onChange={(val) => {
                      if (selectPersonType === User.type.legal) {
                        setFieldValue("names", []);
                      } else {
                        setFieldValue("companyName", []);
                      }
                      setFieldValue(
                        "documentType",
                        handleDocumentTypeChange(val.target.value)
                      );
                    }}
                    onBlur={handleBlur}
                    value={values.documentType}
                    error={touched.documentType && errors.documentType}
                    className="content-select-container"
                  />
                  <Input
                    label={t("moneyWithdrawal.bankNumber")}
                    name="documentNumber"
                    disabled={true}
                    id="document_number"
                    placeholder={t("moneyWithdrawal.documentNumber")}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.documentNumber}
                    error={touched.documentNumber && errors.documentNumber}
                  />
                  {selectPersonType === User.type.legal && (
                    <DvInput
                      label={t("moneyWithdrawal.dv")}
                      type="dv"
                      name="dv2"
                      id="dv2"
                      disabled={true}
                      placeholder={t("register.dv")}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.dv2}
                      error={touched.dv2 && errors.dv2}
                      extraClassname="input__field_less_padding"
                    />
                  )}
                </IdContainer>
              </OwnerAccountContainer>
            </FormFooterRowCustom>
            <MiddleMessage>{t("moneyWithdrawal.middleText")}</MiddleMessage>
            <FormHeaderRow borders={{ bottom: true }}>
              <TextHeader>{t("moneyWithdrawal.bankInfo")}</TextHeader>
            </FormHeaderRow>
            <FormRowCustom background={true} borders={{ bottom: true }}>
              <Select
                name="bank"
                id="bank"
                placeholder={t("moneyWithdrawal.bankName")}
                options={banks}
                onChange={handleChange}
                value={values.bank}
                onBlur={handleBlur}
                error={touched.bank && errors.bank}
              />
            </FormRowCustom>
            <FormRowCustom background={true} borders={{ bottom: true }}>
              <Select
                name="bankAccountType"
                id="bankAccountType"
                placeholder={t("moneyWithdrawal.bankAccountType")}
                options={bankAccountTypes}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankAccountType}
                error={touched.bankAccountType && errors.bankAccountType}
              />
            </FormRowCustom>
            <FormFooterRowCustom
              direction="column"
              className="form-footer-row-custom"
            >
              <Input
                label={t("moneyWithdrawal.bankNumber")}
                name="bankNumber"
                id="bank_number"
                placeholder={t("moneyWithdrawal.bankNumber")}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bankNumber}
                error={touched.bankNumber && errors.bankNumber}
              />
              <Input
                label={t("moneyWithdrawal.bankNumber")}
                name="confirmBankNumber"
                id="confirm_bank_number"
                placeholder={t("moneyWithdrawal.confirmBankNumber")}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmBankNumber}
                error={touched.confirmBankNumber && errors.confirmBankNumber}
              />
            </FormFooterRowCustom>
            <PageSubtitleFooterCustom>
              {t("moneyWithdrawal.descriptionFooter1")}
              <b>{t("moneyWithdrawal.descriptionFooter2")}</b>
              {t("moneyWithdrawal.descriptionFooter3")}
            </PageSubtitleFooterCustom>
            <ButtonContainer>
              <Button
                id="send_button"
                type="submit"
                data-testid="save-button"
                disabled={investmentCancellation?.isRequesting}
              >
                {t("moneyWithdrawal.saveAndContinue")}
              </Button>
            </ButtonContainer>
          </Form>
        )
      }
    </Formik>
  );
};

MoneyWithdrawal.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      response: PropTypes.arrayOf([
        PropTypes.shape({}),
        PropTypes.shape({
          _bank: PropTypes.string
        })
      ]),
      isValidated: PropTypes.bool
    })
  }).isRequired
};

export default MoneyWithdrawal;
